
.statusbar {
  position: relative;
  height: 150px;
  &:before {
    content: ' ';
    position: absolute;
    top: 50%;
    width: calc(100% - 30px);
    border-bottom: 1px solid $color-text-dark;
  }
  ul {
    padding-left: 0;
    display: flex;
    padding-top: 70px;
    justify-content: space-around;
    position: relative;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;
      font-size: 15px;
      color: $color-tertiary;
      transition: all 0.3s ease;
      &:before {
        content: ' ';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: $color-secondary;
        border-radius: 6px;
        border: 1px solid $color-text-dark;
        margin-bottom: 5px;
      }
      &.active {
        color: $color-text;
        &:before {
          width: 10px;
          height: 10px;
          background: $color-danger;
          border: 1px solid $color-danger;
          box-shadow: 0 0 0 4px rgba($color-danger, $alpha: 0.3);
        }
      }
      &.done {
        color: $color-success;
        transform: translateY(-2px);
        &:before {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background: $color-success;
          background: url('../../assets/img/done-white.png') no-repeat center / 14px, $color-success;
        }
      }
    }
  }
}