@import './constant/colors';
@import './constant/typo';
@import './components/fromdata/style';
@import './components/breadcrumb/style';
@import './components/card/style';
@import './components/footer/style';
@import './components/header/style';
@import './components/sectiontitle/style';
@import './components/share/style';
@import './components/sidebar/style';
@import './components/spanner/style';
@import './components/statusbar/style';
@import './components/table/style';
@import './components/productslider/style';
@import './components/tab/style';
@import './components/promobanner/style';
pre {
    white-space: normal;
}
body {
    color: $color-text;
    background-color: $color-primary-light;
    transition: all 0.3s ease;
    input, select {
        outline: none;
    }
    h1, h2, h3, h4, h5, h6, p {
        color: $color-text;
    }

    main #home {
        min-height: calc(100vh - 476px);
        transition: all 0.3s ease;
        input, select {
            outline: none;
        }
    }
    &.open-bugger {
        @media screen and (max-width: 991px) {
            transform: translateX(-250px);
            height: 100vh;
            min-height: 100vh;
            overflow: hidden;
        }
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        padding-left: 0;
        width: 100%;
        li {
            list-style: none;
            margin: 0 10px;
            button {
                border: none;
                background: none;
                color: transparent;
                width: 14px;
                height: 14px;
                border: 1px solid $color-tertiary;
                border-radius: 8px;
                box-sizing: border-box;
                overflow: hidden;
                outline: none;
                cursor: pointer;
            }
            &.slick-active {
                button {
                    background: $color-tertiary;
                }
            }
        }
    }
    .cusbtn {
        font-size: 15px;
        background: $color-dark;
        color: $color-secondary;
        border: none;
        padding: 8px 10px;
        outline: none;
        transition: all .3s ease;
        @media screen and (max-width: 767px) {
            padding: 4px;
            font-size: 12px;
        }
        &:hover {
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        }
        .icn-lft {
            width: 25px;
            transform: translate3d(-5px, -1px, 0);
        }
        .icn-rht {
            width: 25px;
            transform: translate3d(5px, -1px, 0);
        }
    }
}