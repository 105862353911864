.component-tab {
  .tab-listing {
    list-style: none;
    padding-left: 10px;
    border-bottom: 1px solid $color-text-dark;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    li {
      padding: 10px 15px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-bottom: 2px solid transparent;
      &.active {
        border-bottom: 2px solid $color-text-dark;
      }
      &:hover {
        background: rgba($color-text-dark, 0.2);
      }
    }
  }
}