
.title-sec {
  font-size: 24px;
  position: relative;
  font-weight: 'bolder';
  margin-bottom: 30px;
  &:after {
    content: ' ';
    display: inline-block;
    width: 100px;
    height: 1px;
    border-bottom: 1px solid $color-text-dark;
    transform: translateX(20px);
  }
}