
.footer {
  background: $color-dark;
  color: $color-secondary;
  font-family: roboto-light;
  hr {
    margin: 0;
    border-top: 1px solid rgba($color-secondary, 0.2);
  }
  p {
    margin-bottom: 0;
    color: $color-text-dark;
  }
  .info-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
    @media screen and (max-width: 991px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    img {
      width: 50px;
      @media screen and (max-width: 991px) {
        width: 30px;
      }
    }
    .content {
      width: calc(100% - 70px);
      @media screen and (max-width: 991px) {
        width: calc(100% - 50px)
      }
      h5 {
          font-size: 20px;
          color: #FFF;
      }
    }
  }
  h3 {
    font-size: 18px;
    color: #FFF;
  }
  .section-contact {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .contact {
    @media screen and (max-width: 991px) {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      font-size: 15px;
      display: flex;
      justify-content: flex-start;
      color: $color-text-dark;
      .label {
        padding-right: 20px;
        position: relative;
        &:after {
          content: ':';
          position: absolute;
          right: 10px;
        }
      }
      span {
        display: flex;
        flex-wrap: wrap;
        a {
          color: $color-text-dark;
          &::after {
            content: ',';
            margin-right: 5px;
          }
          &:last-child {
            &::after {
                display: none;
            }
          }
        }
      }
    }
  }
  .copyright {
    padding: 15px 0;
    font-size: 14px;
    .general-info {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      list-style: none;
      @media screen and (max-width: 991px) {
        justify-content: flex-start;
        margin-bottom: 15px;
      }
      li {
        padding-right: 10px;
        &::after {
          content: '';
          border-right: 1px solid $color-text-dark;
          padding-right: 10px;
        }
        a {
          color: $color-text-dark;
          transition: all .2s;
          &:hover {
            text-decoration: none;
            color: #FFF;
          }
        }
      }
    }
  }
}