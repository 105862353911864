@font-face {
    font-family: roboto-light;
    src: url('../assets/fonts/Roboto/Roboto-Light.ttf');
}
@font-face {
    font-family: roboto-regular;
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
}
@font-face {
    font-family: roboto-bold;
    src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
}
@font-face {
    font-family: worksan-bold;
    src: url('../assets/fonts/Work_Sans/WorkSans-Bold.ttf')
}
@font-face {
    font-family: worksan;
    src: url('../assets/fonts/Work_Sans/WorkSans-VariableFont_wght.ttf')
}

.title {
    font-family: worksan;
    font-weight: 900;
}