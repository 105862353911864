
.form-data {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  input[type='text'],input[type='password'],input[type='email'],input[type='tel'],textarea{
    border: 1px solid $color-text-dark;
    padding: 5px;
  }
  select {
    padding: 7px 5px;
    border: 1px solid $color-text-dark;
  }
  &.check-filed {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    label {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}