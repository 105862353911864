
.qty-spanner {
  display: flex;
  input {
    width: 60px;
    height: 44px;
    border: 1px solid $color-text-dark;
    border-right: 0;
    background: none;
    padding: 0 10px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      padding: 0 5px;
      width: 40px;
      height: 30px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  button {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid $color-text-dark;
    background: none;
    @media screen and (max-width: 767px) {
      width: 15px;
      height: 15px;
    }
    &:after {
      content: ' ';
      display: inline-block;
      border: 5px solid transparent;
      border-top-color: $color-danger;
      transform: translateY(3px);
      @media screen and (max-width: 767px) {
        border: 4px solid transparent; 
        border-top-color: $color-danger;
        transform: translate3d(-3px, 2px, 0);
      }
    }
    &:first-child {
      border-bottom: 0;
      &::after {
        border-top-color: transparent;
        border-bottom-color: $color-danger;
        transform: translateY(-3px);
        @media screen and (max-width: 767px) {
          border-bottom-color: $color-danger;
          transform: translate3d(-3px, -3px, 0);
        }
      }
    }
  }
}