.breadcrumb {
  margin-bottom: 0;
  padding: 30px 0;
  background: transparent;
  @media screen and (max-width: 991px) {
    padding: 20px 0;
  }
  h2 {
    margin-bottom: 0;
    font-size: 24px;
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    padding-left: 0;
    display: flex;
    margin-bottom: 0;
    li {
      text-transform: capitalize;
      list-style: none;
      color: $color-tertiary;
      font-size: 13px;
      a {
        color: $color-tertiary;
      }
      &:not(:last-child):after {
        content: '>';
        margin: 0 15px;
        color: $color-text-dark;
      }
    }
  }
}