
.pc-secnav {
    background-color: $color-primary;
    font-family: roboto-regular;
    > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 767px) {
            justify-content: flex-end;
        }
    }
    .socials {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: flex-start;
        @media screen and (max-width: 767px) {
            display: none;
        }
        a {
            width: 37px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba($color-tertiary, 0.5);
            transition: all 0.3s ease;
            &:hover {
                opacity: 0.7;
            }
        }
        li:first-child {
            a {
                border-left: 1px solid rgba($color-tertiary, 0.5);
            }
        }
    }
    .pc-option {
        display: flex;
        align-items: center;
        form {
            position: relative;
            border-right: 1px solid $color-tertiary;
            padding-right: 15px;
            height: 100%;
            @media screen and (max-width: 767px) {
                display: none;
            }
            img {
                position: absolute;
                right: 15px;
                top: 5px;
            }
        }
        input {
            min-width: 260px;
            border: none;
            border-bottom: 1px solid $color-tertiary;
            background: none;
            outline: none;
        }
        .langs {
            list-style: none;
            padding-left: 0;
            background: none;
            border: none;
            margin-left: 15px;
            @media screen and (max-width: 767px) {
                padding-top: 10px;
                padding-bottom: 10px;
                border-left: 1px solid $color-tertiary;
                padding-left: 15px;
            }
        }
    }
    .mb-reqsearch {
        display: none;
        @media screen and (max-width: 767px) {
            display: block;
        }
    }
}
.pc-prinav {
    background: $color-secondary;
    box-shadow: 0px 3px 10px #B8B8B829;
    z-index: 1;
    position: relative;
    font-family: roboto-regular;
    > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }
    .logo {
        margin-right: 20px;
        margin-bottom: 0;
        img {
            width: 155px;
            @media screen and (max-width: 1199px) {
                width: 100px;
            }
        }
    }
    .nav-link {
        display: flex;
        justify-content: flex-start;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        font-size: 14px;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            font-size: 12px;
        }
        @media screen and (max-width: 991px) {
            background: $color-secondary;
            display: block;
            position: fixed;
            top: 0;
            left: 100%;
            padding: 0 20px;
            width: 250px;
            height: 100vh;
            box-shadow: 1px 1px 10px rgba($color-tertiary, 0.5);
        }
        li {
            margin-right: 20px;
            @media screen and (max-width: 991px) {
                padding: 20px 0;
                width: 100%;
            }
        }
        a {
            color: $color-dark;
            transition: all .2s;
            &:hover {
                text-decoration: none;
                color: $color-primary;
            }
        }
    }
    .profile {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        font-size: 12px;
        position: absolute;
        top: calc(50% - 14px);
        right: 90px;
        p {
            margin-bottom: 0;
            color: #FFF;
            background: #323232;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .profile-popup {
        width: 130px;
        max-height: 130px;
        padding: 10px;
        background: $color-secondary;
        box-shadow: 1px 1px 10px rgba($color-primary, .5);
        position: absolute;
        top: 60px;
        right: 0;
        border-radius: 5px;
        transition: all 0.6s ease;
        visibility: hidden;
        opacity: 0;
        transform: translateY(30px);
        .profile-item {
            padding-left: 0;
            list-style: none;
            max-height: 300px;
            margin-bottom: 0;
            li {
                margin-bottom: 5px;
                a {
                    color: $color-text;
                    transition: all .2s;
                    &:hover {
                        text-decoration: none;
                        color: $color-primary;
                    }
                    img {
                        width: 14px;
                        height: 14px;
                        margin-right: 10px;
                    }
                }
            }
        }
        &.active {
            visibility: visible;
            opacity: 1;
            transform: translateY(0px);
        }
    }
    figure {
        margin-bottom: 0;
    }
    .cart {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        font-size: 12px;
        position: absolute;
        top: calc(50% - 14px);
        right: 30px;
        cursor: pointer;
        .badge {
            min-width: 18px;
            height: 18px;
            border-radius: 15px;
            color: $color-secondary;
            position: absolute;
            top: -16px;
            left: 4px;
            background: #FF615E;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media screen and (max-width: 991px) {
            right: 60px
        }
        img {
            width: 28px
        }
        figcaption {
            font-size: 8px;
            color: $color-tertiary;
            @media screen and (max-width: 1199px) {
                display: none;
            }
        }
        
    }
    .cart-popup {
        width: 420px;
        max-height: 350px;
        padding: 15px;
        background: $color-secondary;
        box-shadow: 1px 1px 10px rgba($color-primary, .5);
        position: absolute;
        top: 60px;
        right: 0;
        border-radius: 5px;
        transition: all 0.6s ease;
        visibility: hidden;
        opacity: 0;
        transform: translateY(30px);
        &.active {
            visibility: visible;
            opacity: 1;
            transform: translateY(0px);
        }
        h4 {
            font-size: 14px;
            font-weight: bold;
        }
        ul {
            padding-left: 0;
            list-style: none;
            max-height: 300px;
            li {
                padding: 15px 0;
                border-bottom: 1px solid $color-text-dark;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .img {
                    width: 40px;
                    height: 40px;
                    display: block;
                    border: 1px solid rgba($color-tertiary, 0.2)
                }
                &:first-child {
                    margin-top: 0;
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
        .del-btn {
            width: 15px
        }
    }
    .menu-bugger {
        display: none;
        @media screen and (max-width: 991px) {
            display: block;
            position: absolute;
            right: 15px;
            top: calc(50% - 18px);
        }
    }
}
.mb-search {
    padding: 0;
    height: 0;
    display: none;
    transition: all 0.3s ease;
    background: $color-secondary;
    @media screen and (max-width: 767px) {
        display: flex;
        height: 0;
        opacity: 0;
        * {
            display: none;
        }
        &.active {
            opacity: 1;
            padding: 5px;
            height: 45px;
            * {
                display: block;
            }
        }
    }
    input {
        width: calc(100% - 100px);
        height: 35px;
        padding: 0 10px;
        border: 1px solid $color-primary;
    }
    button {
        width: 100px;
        height: 35px;
        background: $color-primary;
        border: none;
    }
}
