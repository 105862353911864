.slick-promo {
  margin-left: -10px;
  margin-right: -10px;
  @media screen and (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
  figure {
    padding-right: 10px;
    padding-left: 10px;
    @media screen and (max-width: 991px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .slick-dots {
    li {
      margin: 0 5px;
      line-height: 0;
      button {
        border: 1px solid $color-primary;
      }
    }
    li.slick-active {
      button {
        background: $color-primary;
      }
    }
  }
  .slick-track {
    margin: 0;
  }
}