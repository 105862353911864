
.table {
  thead {
    th {
      border-top: 1px solid transparent;
      border-bottom: 1px solid $color-text-dark;
      font-size: 16px;
      font-weight: normal;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  td {
    border-bottom: 1px solid $color-text-dark;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  tbody {
    font-size: 15px;
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}