$color-primary  : #89CFF1;
$color-primary-light: #D3E9F0;
$color-primary-dark: #00ACFF;
$color-secondary: #FFFFFF;
$color-tertiary : #616161;
$color-text     : #323232;
$color-text-dark: #B8B8B8;
$color-dark     : #111111;
$color-danger   : #FF615E;
$color-success  : #0a8e2e;
$color-facebook :#3B5998;