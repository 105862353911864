.product-slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
  .navigator {
    width: 90px;
    list-style: none;
    padding-left: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    li {
      margin-bottom: 15px;
      &:focus {
        outline: none;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
        display: block;
        padding: 0 5px;
      }
      img {
        width: 90px;
        &:focus {
          outline: none;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .slick-arrow {
      color: transparent;
      width: 100%;
      height: 18px;
      border: none;
      position: absolute;
      z-index: 1;
      outline: none;
      @media screen and (max-width: 767px) {
        display: none!important;
      }
      &.slick-next {
        transform: translateY(-32px);
        background: url('../../assets/img/downarrow-white.png') no-repeat center / 20px, rgba($color-dark, 0.5);
      }
      &.slick-prev {
        background: url('../../assets/img/uparrow-white.png') no-repeat center / 20px, rgba($color-dark, 0.5);
      }
    }
    &:after {
      content: ' ';
      width: 100%;
      height: 18px;
      background: $color-primary;
      position: absolute;
      bottom: 12px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .product-img {
    width: calc(100% - 105px);
    list-style: none;
    padding-left: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    img {
      width: 100%;
    }
    .slick-arrow {
      color: transparent;
      width: 32px;
      height: 32px;
      border: none;
      background: rgba($color-dark, 0.5);
      position: absolute;
      z-index: 1;
      top: calc(50% - 16px);
      outline: none;
      &.slick-next {
        right: 0;
        background: url('../../assets/img/rightarrow-white.png') no-repeat center / 20px, rgba($color-dark, 0.5);
      }
      &.slick-prev {
        background: url('../../assets/img/leftarrow-white.png') no-repeat center / 20px, rgba($color-dark, 0.5);
      }
    }
  }
}