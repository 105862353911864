.card-col {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  @media screen and (max-width: 991px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  .card-default {
    display: block;
    background-color: #FFF;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    transition: all .3s;
    @media screen and (max-width: 991px) {
      margin-bottom: 10px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    figure {
      margin-bottom: 0;
      .card-img {
        img {
          width: 100%;
          opacity: 0;
        }
      }
    }
    .card-info {
      padding: 10px 15px 15px;
      h3 {
        font-size: 16px;
        margin-bottom: 40px;
      }
      .card-info-bottom {
        position: absolute;
        bottom: 15px;
        p {
          font-size: 18px;
          font-weight: 600;
          font-family: 'roboto-bold', sans-serif;
          margin-bottom: 0;
          span {
            margin-right: 5px;
          }
        }
      }
    }
    .addcart {
      background: $color-primary-dark;
      position: absolute;
      right: 15px;
      bottom: 15px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
      }
      &:hover {
        background-color: $color-primary;
      }
    }
  }
}

.card-default:hover {
  text-decoration: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}