

.sidebar {
  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }
  .sidebar-hover {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
    }
    button {
      display: none;
    }
  }
  .sidebar-hover.active {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    z-index: 9;
    overflow: hidden;
    button {
      display: block;
      border: 0 none;
      background: transparent;
      width: 100%;
      text-align: right;
      img {
        width: 20px;
        margin: 15px;
      }
    }
  }
  h2 {
    font-size: 16px;
    margin-bottom: 30px;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    position: relative;
    @media screen and (max-width: 991px) {
      display: none;
    }
    &::before {
      content: '';
      border-left: 3px solid $color-text;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
  .sidebar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #aedcf1;
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 0;
      border: 0 none;
    }
    li.side {
      margin-bottom: 1px;
      cursor: pointer;
      padding: 10px 15px;
      background:#aedcf1;
      position: relative;
      transition: all .3s;
      &:hover {
        background-color: $color-primary;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding-left: 5px;
        padding-right: 5px;
        background: transparent;
        position: static;
      }
      p {
        margin-bottom: 0;
        @media screen and (min-width: 992px) {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
        .sidebar-img {
          margin-right: 10px;
          display: inline-block;
          border-radius: 5px;
          flex: none;
          @media screen and (max-width: 991px) {
            display: block;
            margin: 0;
          }
          img {
            opacity: 0;
            width: 100%;
          }
        }
        .sidebar-text {
          position: relative;
          font-family: 'roboto-regular';
          color: $color-text;
          @media screen and (max-width: 991px) {
            display: block;
            text-align: center;
            padding: 10px;
            font-size: 14px;
          }
        }
      }
      &:hover {
        .sidebar-text {
          &:after {
            width: 100%;
            transform: scaleX(1);
          }
        }
      }
      .mega-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 103%;
        min-width: 255px;
        z-index: 99;
        transform: scaleY(0);
        opacity: 0;
        visibility: hidden;
        transform-origin: 0 0 0;
        transition: all 0.2s ease 0s;
        background: #aedcf1;
        border: 1px solid #aedcf1;
        @media screen and (max-width: 991px) {
          left: 50%;
          transform: translateX(-50%);
        }
        &::before {
          background-color: #aedcf1;
          border-color: #aedcf1 transparent transparent #aedcf1;
          border-image: none;
          border-style: solid;
          border-width: 1px;
          content: "";
          display: block;
          height: 15px;
          left: -6px;
          position: absolute;
          top: 15px;
          transform: rotate(-45deg);
          width: 15px;
          z-index: -1;
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
        .mega-item {
          padding: 5px 15px;
          transition: all .3s;
          &:hover {
            background-color: $color-primary;
          }
          .mega-link {
            &:hover {
              text-decoration: none;
            }
          }
          .mega-img {
            margin-right: 10px;
            display: inline-block;
            border-radius: 5px;
            img {
              width: 100%;
              opacity: 0;
            }
          }
          .mega-text {
            position: relative;
            font-family: roboto-regular;
            color: $color-text;
          }
        }
      }
    }
    li.side.active {
      background-color: $color-primary;
      .sidebar-text {
        &:after {
          width: 100%;
          transform: scaleX(1);
        }
      }
      .mega-menu {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
        @media screen and (max-width: 991px) {
          top: 50%;
          left: 50%;
          max-width: 500px;
          width: 100%;
          transform: translate3d(-50%, -50%, 0);
          max-height: 100%;
          overflow-y: auto;
        }
        @media screen and (max-width: 767px) {
          max-width: 90%;
        }
      }
    }
  }
}