.share-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  li {
    margin-right: 5px;
    .social-link {
      display: block;
      img {
        width: 26px;
      }
    }
  }
}